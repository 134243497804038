<template>
	<div>
		<div class="card">
			<div class="card-body">
				<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
					<div class="row">
						<div class="col-md-12 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord" v-if="filters.status == 'C'">Nuevo pago</a-button>
							<a-button class="btn btn-info ml5" icon="plus" @click="onAddRecordTax" v-if="filters.status == 'C'">Referenciados</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 pt20 text-right">
							<a-radio-group :value="filters.status" @change="handleStatusChange">
								<a-radio-button value="C"> Cotización </a-radio-button>
								<a-radio-button value="0"> Por autorizar </a-radio-button>
								<a-radio-button value="1"> Por pagar </a-radio-button>
								<a-radio-button value="2"> Pagadas </a-radio-button>
							</a-radio-group>
						</div>
					</div>
					<div class="row pt20">
						<div class="col-md-3">
							<b># de ODC</b>
							<a-input v-model="filters.ticket" v-mask="'####'" v-on:keyup.enter="searchByTicket" allowClear @change="onResetTicket" />
						</div>
						<div class="col-md-3" v-if="showFilters">
							<b>Rango de Fecha <small>(Registro)</small></b>
							<a-range-picker style="width: 100%" @change="initModule(true)" v-model="defaultDate" :default-value="defaultDate" :format="dateFormat" />
						</div>
						<div class="col-md-3" v-if="showFilters">
							<b>Proveedor</b>
							<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="filters.supplier_id" style="width: 100%" @change="initModule(false)">
								<a-select-option v-for="(supplier, index) in suppliersList" :key="index" :value="supplier.id">{{ supplier.full_name.toUpperCase() }}</a-select-option>
							</a-select>
							<small @click="getSuppliersList" class="pointer">Recarga</small>
						</div>
					</div>
					<div class="row" :class="Number(filters.status) == 2 ? '' : 'pt20'" v-if="filters.status == 'R'">
						<div class="col-md-12">
							<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="getColumns" :dataSource="purchaseOrdersList" rowKey="id" bordered
									 class="pt20">
								<div slot="description" slot-scope="record">
									{{ `${record.quantity}` }}x {{ record.measurement_unit == 'piece' ? 'Pieza' : record.measurement_unit == 'liter' ? 'Litro' : 'Servicio' }} - {{ record.description }}
								</div>
								<div slot="service_order" slot-scope="record">
									<a-tag color="purple" v-if="record.service_order > 0">ODS {{ record.service_order }}</a-tag>
									<span v-else>- N/A -</span>
								</div>
								<div slot="action" slot-scope="record">
									<button class="btn btn-danger ant-btn-sm ant-btn-icon-only ml7" v-if="userPermition" @click="onDelete(record.id)">
										<a-icon type="delete" />
									</button>
									<span v-else>- N/A -</span>
								</div>
							</a-table>
						</div>
						<div class="col-md-12 text-right">
							<a-button class="btn btn-primary ml7" icon="plus" @click="onAuthorize" v-if="selectedRowKeys.length">Generar ODC de {{ selectedRowKeys.length }} elementos</a-button>
						</div>
					</div>
					<div class="row" :class="Number(filters.status) == 2 ? '' : 'pt20'" v-else>
						<div class="col-md-12">
							<a-table :columns="getColumns" :dataSource="purchaseOrdersList" rowKey="id" bordered>
								<div slot="payment_type" slot-scope="record">
									<div>
										{{ record.business_name }}
									</div>
									<div v-if="record.is_tax">
										{{ record.elements[0].description.toUpperCase() }}
										<br />
										<a-tag color="blue">Digital</a-tag>
									</div>
									<div v-else-if="record.payment_method_data">
										<a-tag color="green" v-if="JSON.parse(record.payment_method_data).payment_method == 1"> Efectivo </a-tag>
										<a-tag color="blue" v-else>Digital</a-tag>
									</div>
									<div v-else>- No establecido -</div>
									<a-tag color="pink" v-if="record.sat_payment_method == 'NEXT'"> Por pagar: {{ moment(record.credit_payment_date).format('DD-MM-YYYY') }} </a-tag>
									<div v-if="record.sat_payment_method == 'PPD'">
										<div v-for="(paymentDate, ppdIndex) in record.ppd_payments" :key="ppdIndex">
											<a-tag color="pink" v-if="!paymentDate.paymentReceipt"> Por pagar: {{ moment(paymentDate.paymentDate).format('DD-MM-YYYY') }} </a-tag>
										</div>
									</div>
								</div>
								<div slot="order_date" slot-scope="record">
									<div>{{ moment(record.created_at).format('DD-MM-YYYY') }}</div>
									<div>{{ record.name }} {{ record.surname }}</div>
								</div>
								<div slot="order" slot-scope="record" class="text-left">
									<div v-for="element, key in record.elements" :key="key">
										- {{ element.description }}
									</div>
								</div>
								<div slot="subtotal" slot-scope="record" class="text-right">
									{{ numeral(record.total / 1.16).format('$0,0.00') }}
								</div>
								<div slot="iva" slot-scope="record" class="text-right">
									{{ numeral((record.total / 1.16) * .16).format('$0,0.00') }}
								</div>
								<div slot="withheld_taxes" slot-scope="record" class="text-right">
									{{ numeral(record.total_impuestos_retenidos).format('$0,0.00') }}
								</div>
								<div slot="amount" slot-scope="record" class="text-right">
									{{ numeral(record.total - record.total_impuestos_retenidos).format('$0,0.00') }}
								</div>
								<div slot="action" slot-scope="record">
									<a-button class="btn btn-success" icon="check" v-if="userPermition && record.status == 0 && record.blocked == true" @click="onChangeStatus(record.id)" />
									<a-button class="ml5 btn" :class="record.total ? 'btn-warning' : 'btn-info'" :icon="record.total ? 'eye' : 'edit'" @click="onEditRecord(record.id)" />
									<a-button class="ml5 btn btn-danger" icon="close" v-if="record.status == 0" @click="onDisableRecord(record.id)" />
								</div>
							</a-table>
						</div>
					</div>
				</a-spin>
			</div>
		</div>
		<a-modal width="50%" :visible="modalTaxVisible" title="Pago referenciado" :closable="true" @cancel="onCloseModalTax">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalTax" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateTax" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<taxesODC ref="modalTax" :visible="modalTaxVisible" @close="onCloseModalTax" />
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import taxesODC from '@/components/taxesODC'
import utilities from '@/services/utilities'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'PurchaseOrders',
	directives: {
		mask,
	},
	components: {
		taxesODC,
	},
	data() {
		return {
			defaultDate: [moment().startOf('day'), moment().endOf('day')],
			columns: {
				P: [
					{
						title: '# ODC',
						dataIndex: 'id',
						key: 'id',
						align: 'center',
						width: '10%',
					},
					{
						title: 'Fecha / Registro',
						scopedSlots: { customRender: 'order_date' },
						align: 'center',
						width: '10%',
					},
					{
						title: 'Proveedor / Tipo de pago',
						scopedSlots: { customRender: 'payment_type' },
						align: 'center',
						width: '20%',
					},
					{
						title: 'Concepto de Pago',
						scopedSlots: { customRender: 'order' },
						align: 'center',
						width: '40%',
					},
					// {
					// 	title: 'Subtotal',
					// 	scopedSlots: { customRender: 'subtotal' },
					// 	align: 'center',
					// 	width: '200',
					// },
					// {
					// 	title: 'IVA',
					// 	scopedSlots: { customRender: 'iva' },
					// 	align: 'center',
					// 	width: '200',
					// },
					// {
					// 	title: 'Retenciones',
					// 	scopedSlots: { customRender: 'withheld_taxes' },
					// 	align: 'center',
					// 	width: '200',
					// },
					{
						title: 'Total a Pagar',
						scopedSlots: { customRender: 'amount' },
						align: 'center',
						width: '10%',
					},
					{
						title: 'Acciones',
						scopedSlots: { customRender: 'action' },
						align: 'center',
						width: '10%',
					},
				],
				R: [
					{
						title: 'Solicitud',
						scopedSlots: { customRender: 'description' },
						align: 'center',
					},
					{
						title: 'Orden de servicio',
						scopedSlots: { customRender: 'service_order' },
						align: 'center',
					},
					{
						title: 'Acciones',
						scopedSlots: { customRender: 'action' },
						align: 'center',
					},
				],
			},
			dateFormat,
			orderModal: false,
			elements: [],
			selectedRowKeys: [],
			modalTaxVisible: false,
			modalBankCommissionVisible: false,
			modalRefundVisible: false,
		}
	},
	computed: {
		...mapGetters('purchaseOrders', ['purchaseOrdersList', 'filters', 'totalPurchaseOrders']),
		...mapGetters('user', ['user', 'isUserAdmin']),
		...mapGetters('suppliers', ['suppliersList']),
		...mapGetters('auth', ['userData']),
		showFilters() {
			return !['C', '0', '1'].includes(this.filters.status)
		},
		userPermition() {
			return ['super-admin', 'admin'].includes(this.userData.role)
		},
		isUserAdmin() {
			return !!this.user.is_admin
		},
		spinnerLoaderLabel() {
			return this.$store.state.purchaseOrders.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.purchaseOrders.spinnerLoader
		},
		getColumns() {
			if (this.filters.status == 'R') {
				return this.columns.R
			} else {
				return this.columns.P
			}
		},
	},
	mounted() {
		this.initModule()
		this.getSuppliersList()
	},
	methods: {
		numeral,
		moment,
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET')
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		handleStatusChange(e) {
			this.$store.commit('purchaseOrders/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
				},
			})
			this.initModule()
		},
		initModule(needStatus = true) {
			//
			let payload = {
				status: this.filters.status == '' ? 'C' : needStatus ? this.filters.status : false,
				supplier_id: utilities.objectValidate(this.filters, 'supplier_id', false) ? this.filters.supplier_id : false,
			}

			if (this.defaultDate.length == 2) {
				payload = {
					...payload,
					startDate: moment(this.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.defaultDate[1]).format('YYYY-MM-DD'),
				}
			}

			// Si la sección es cotización o por autorizar, se retira rango de fecha
			if (!this.showFilters) {
				delete payload.startDate
				delete payload.endDate
			}

			this.$store.dispatch('purchaseOrders/GET', payload)
		},
		onAddRecord() {
			this.$router.push('/purchase-orders/create')
		},
		onAddRecordBank() {
			//
			console.log('onAddRecordBank')
		},
		onEditRecord(id) {
			this.$store.dispatch('purchaseOrders/SET_LOCAL', id)
		},
		onChangeStatus(id) {
			let total = this.purchaseOrdersList.find((e) => e.id == id).total

			if (!total) {
				//
				Swal.fire({
					title: 'Atención',
					html: `No se puede cambiar el estatus de la ODC sin establecer el monto total de la compra.`,
					icon: 'warning',
					confirmButtonText: 'Ok',
					cancelButtonText: 'Cancelar',
				})
			} else {
				Swal.fire({
					title: 'Atención',
					text: '¿Deseas autorizar la ODC?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar',
					cancelButtonText: 'Cancelar',
				}).then((result) => {
					if (result.isConfirmed) {
						this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
							id,
							status: 1,
						})
					}
				})
			}
		},
		onDisableRecord(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas rechazar la ODC?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let total = this.purchaseOrdersList.find((e) => e.id == id).total
					let inputOptions = {}

					if (!total) {
						inputOptions = {
							3: 'INTERNO - Cambio/error de proveedor/método de pago',
							4: 'INTERNO - Cambio/error de unidad de medida',
							5: 'INTERNO - Cambio/error de cantidad',
							6: 'INTERNO - Cambio/error de total',
							7: 'INTERNO - Cambio/error de descripción',
						}
					} else {
						inputOptions = {
							1: 'PROVEEDOR - Cambio de precio/cotización',
							2: 'PROVEEDOR - Existencia/capacidad de entrega',
							3: 'INTERNO - Cambio/error de proveedor/método de pago',
							4: 'INTERNO - Cambio/error de unidad de medida',
							5: 'INTERNO - Cambio/error de cantidad',
							6: 'INTERNO - Cambio/error de total',
							7: 'INTERNO - Cambio/error de descripción',
						}
					}

					Swal.fire({
						title: 'Selecciona el motivo de rechazo',
						input: 'select',
						inputOptions,
						showCancelButton: true,
						confirmButtonText: 'Continuar',
						cancelButtonText: 'Cancelar',
						inputPlaceholder: 'Selecciona',
						inputValidator: (value) => {
							return new Promise((resolve) => {
								if (!isNaN(value)) {
									value = Number(value)
									if (!value || value < 0) {
										resolve('Es necesario elegir un motivo')
									}
								} else {
									resolve('Es necesario elegir un motivo')
								}
								resolve()
							})
						},
					}).then((resultReason) => {
						if (resultReason.value != '' && resultReason.isConfirmed) {
							//
							let reject_reason_id = Number(resultReason.value)
							let status = reject_reason_id == 1 || reject_reason_id == 2 ? 0 : -1

							let payload = {
								id,
								status,
								reject_reason_id,
								elements: this.purchaseOrdersList.find((e) => e.id == id).elements.map((e) => e.ie_id),
							}
							this.$store.dispatch('purchaseOrders/UPDATE_STATUS', payload)
						}
					})
				}
			})
		},
		authorizingUser(payload) {
			let response = ''
			let approved_by = JSON.parse(payload)
			response = `${approved_by.name} ${approved_by.first_lastname} ${approved_by.second_lastname || ''}`
			return response
		},
		resetVariables() {
			this.$store.dispatch('purchaseOrders/RESET_FILTERS')
			this.initModule()
		},
		onOpenModal(elements) {
			this.elements = _.cloneDeep(elements)
			this.orderModal = true
		},
		onCloseModal() {
			this.elements = []
			this.orderModal = false
		},
		onAuthorize() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar ODC a partir de los productos seleccionados?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				//
				if (result.isConfirmed) {
					let elements = this.purchaseOrdersList.map((e) => {
						if (this.selectedRowKeys.includes(e.id)) {
							return e
						}
					})
					let filtered = elements.filter((x) => {
						return x !== undefined
					})
					await this.$store.dispatch('impressment/CONFIRM', { elements: filtered, fromPurchaseOrders: true })
					this.selectedRowKeys = []
					this.initModule()
				}
			})
		},
		onDelete(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				//
				if (result.isConfirmed) {
					this.$store.dispatch('impressment/DELETE', { id, fromPurchaseOrders: true })
				}
			})
		},
		onAddRecordTax() {
			this.modalTaxVisible = true
		},
		onCreateTax() {
			//
			this.$refs.modalTax.onSaveRecord()
		},
		onCloseModalTax() {
			this.modalTaxVisible = false
		},
		onAddBankCommission() {
			this.modalBankCommissionVisible = true
		},
		onRefund() {
			this.modalRefundVisible = true
		},
		onCloseModalRefundVisible() {
			this.modalRefundVisible = false
			this.$refs.modalRefund.resetVariables()
		},
		onCreateBankCommission() {
			this.$refs.modalBankCommission.onSaveRecord()
		},
		onCreateRefund() {
			this.$refs.modalRefund.onSaveRecord()
		},
		onCloseModalBankCommission() {
			this.modalBankCommissionVisible = false
		},
		searchByTicket() {
			let payload = {
				ticket: this.filters.ticket,
			}
			this.$store.dispatch('purchaseOrders/GET', payload)
		},
		onResetTicket(e) {
			if (e.target.value == '') {
				this.initModule()
			}
		},
	},
}
</script>

